$(function () {

  // jquery.fadeMover
  $('body').fadeMover({
    'effectType': 1,
    'inSpeed': 800,
    'outSpeed': 800,
    'inDelay': '0',
    'outDelay': '0',
    'nofadeOut': 'nonmover'
  });

  // スライダー
  var mySwiper = new Swiper('.swiper-container', {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  // 値段バリエーション
  $('.price-variation li').on('click', function () {
    var index = $('.price-variation li').index(this);
    $('.price-grade span, .price span, .price a, .price-img img').css('display','none');
    $('.price-grade span').eq(index).css('display','inline');
    $('.price span').eq(index).css('display','inline');
    $('.price a').eq(index).css('display','block');
    $('.price-img img').eq(index).css('display','inline');
    $('.price-variation li').removeClass('active');
    $(this).addClass('active')
    if ( $(this).hasClass('cheap-reason') ) {
      $(this).parents().find('.notice-text.cheap-reason').css('display','block');
    } else {
      $(this).parents().find('.notice-text').css('display','none');
    }
  });

}());
